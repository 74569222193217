
<template>
  <div id="main">
   <template v-if="show"> 
  <scsjNav></scsjNav > 
  <label_scsj></label_scsj>
   </template> 
   <template v-else>
   <mt-spinner class="wait" type="fading-circle" color="#26a2ff" :size="60"></mt-spinner>
   </template> 
  </div>
</template>

<style scoped lang="scss">

</style>

<script>

import scsjNav from '@/components/navbar_scsj.vue';
import label_scsj from '@/components/label_scsj.vue';

export default{

components:{
 scsjNav:scsjNav,
  label_scsj:label_scsj,

},

data:function(){
return {
    isNavFixed:false,
  }
},

beforeRouteUpdate(to,from,next){
console.log('beforeRouteUpdate')
this.$store.state.itemLabelId = ""
this.$store.dispatch('getLcLabel',to.params.lc)
next()
},

beforeRouteEnter(to,from,next){
//router.app.$store.dispatch('getLcLabel',to.params.lc)
next(vm=>{
//console.log(vm)
vm.$store.state.itemLabelId = ""
vm.$store.dispatch('getLcLabel',to.params.lc)
//console.log(vm.$store)
})
},

computed:{
  show(){
   let lc = this.$route.params.lc
   let obj = this.$store.state.lcLabel
   let lcb = obj[lc]
    return lcb?true:false
  },
},
methods:{


},

}
</script>
