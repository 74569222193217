
<template>
  <div id='main'>
   <template v-if="show =='selectGroup'">
    <div id ='tip'><a>请选择分组</a></div>
   </template> 
   <template v-else-if="show"> 
    <div class="label" v-for="(label,l) in state.itemLabel[state.itemLabelId]" :key="l">
      <div class="label_title" ><a>{{label.title}}</a></div>
                <table>
              <tr v-for="(item,i) in label.item" :key="i" >
                  <td style="width:50%">{{item.title}}</td>
                  <td>{{state.itemValue[item.index]}}</td>
                  <td style="width:18%">{{item.unit}}</td>
                </tr>
                </table>
    </div>
   </template> 
   <template v-else>
   <mt-spinner class="wait" type="fading-circle" color="#26a2ff" :size="60"></mt-spinner>
   </template> 
  <br>
  <br>
  <br>
  <br>
  </div>
</template>



<style scoped lang="scss">
  #main{
   margin:20px;

  }
  
  #tip{
    text-align:center;
    font-size:2em;
  }  

  .label{
    margin-bottom:2em; 
  }

  .label_title{
    text-align:center;
    margin-bottom:0.5em;
    font-size:large;
  }

  table{
  border-collapse: collapse;
  border: none;
  width:100%;
    tr{
      border: solid #000 1px;
      td{
        border: solid #000 1px;
        padding:0.4em;
      }
    }
  }

table tr:nth-child(odd){
 background-color: #ddf2fe;
}
  
</style>

<script>


export default{
  data(){
    return{
      state:this.$store.state,
      itemValue:[],

    }

  },
mounted(){
          let id = this.$store.state.itemLabelId
          if (id && id!="selectGroup"){
          this.$socket.emit('getItemValue',{
          lc:this.$route.params.lc,
          fid:id
          })
}

},
  computed:{
    show(){
      if (state.itemLabelId == 'selectGroup'){return 'selectGroup'}
      if (!state.itemLabelId){return false}
      return state.itemLabel[state.itemLabelId]?true:false
    },
    Fid(){
      return state.itemLabelId 
    },
  },
  methods:{
  },
sockets:{ 
      connect:function() {
        console.log('连接成功');
        
        if ((state.itemLabelId != "selectGroup") && (state.itemLabelId)){
          this.$socket.emit('getItemValue',{
          lc:this.$route.params.lc,
          fid:(state.itemLabelId == 'selectGroup')?"":state.itemLabelId
          })
        } 
      },
      itemValue:function(obj) {
       //console.log(new Date(),'itemValue',obj);
        if (state.itemLabelId == obj.fid){
            this.$store.commit('setItemValue',obj.value)
        }
        else{
          this.$socket.emit('getItemValue',{
          lc:this.$route.params.lc,
          fid:(state.itemLabelId == 'selectGroup')?"":state.itemLabelId
          })
        }
      }
    },
watch:{
      Fid(nv,ov){
          if (!nv || nv=="selectGroup"){
          state.itemValue = []
          this.$socket.emit('getItemValue',{
          lc:this.$route.params.lc,
          fid:""
          })
            return
          }else{
          state.itemValue = []
          this.$socket.emit('getItemValue',{
          lc:this.$route.params.lc,
          fid:(state.itemLabelId == 'selectGroup')?"":state.itemLabelId
          })
          }
      }

},

//}

}

</script>
