<template>
  <div id='main'>
	<mt-header class="navbar_scsj_mt_header" :title="title" id="header" :class="{'is_fixed_header':isFixed_header}">
  </mt-header>


   <mt-actionsheet :actions="page" v-model="pageSheetVisible"></mt-actionsheet>
   <mt-actionsheet :actions="pageGroup" v-model="groupSheetVisible"></mt-actionsheet>
   
	<mt-tabbar class='navbar_scsj_mint_tabbar'  fixed>
  <mt-tab-item 
    @click.native="pageSheetVisible=!pageSheetVisible" 
    style="background-color:#26a2ff;color:#FFFFFF" >
  <a>分页</a>
  </mt-tab-item>
  <mt-tab-item 
			@click.native="groupSheetVisible=!groupSheetVisible" 
			style="background-color:#26a2ff;color:#FFFFFF" >
	<a>分组</a>
	</mt-tab-item>
	</mt-tabbar>	

  </div>
</template>

<style scoped lang="scss"> 
  .is_fixed_header{
    position: fixed;
    top: 0;
    z-index: 999;
    width:100%;
  }

 
</style>

<style lang="scss"> 
    .navbar_scsj_mint_tabbar {
    padding: 0;
    height:40px;
        .mint-tab-item-label{
            padding:5px;
            font-size:1em;
        }
    }

    
    
    .navbar_scsj_mt_header{
      .mint-header-title {
      width:100%;
      };
      .mint-header-button{
        display:none;
      }
    }
</style>

<script>


export default{
name:'scsjNav',
data(){
return {
  pageSheetVisible:false,
  groupSheetVisible:false,
  pageGroup:[],
  title_page: "",
  title_group : "",
  //show_time : false,
	isFixed_header: false,
  offsetTop_header: 0,
} 

},
watch: {
    "$route" (to, from) {
				this.title_page = ""	
        this.title_group = ""
    }
  },
//beforeRouteUpdate(to,from,next){
//this.$store.dispatch('getLcLabel',to.params.lc)
//next()
//},
computed:{
  title(){
    return this.navTitle + this.updateTime
  },
  page(){
   let lc = this.$route.params.lc
   let obj = this.$store.state.lcLabel
   let lcb = obj[lc]
    if (lcb){
     return lcb.page.map(item=>({value:item,name:item.title,method:this.setPage}));
    }else{
      return []
    }
  },
  navTitle(){
   let lc = this.$route.params.lc
   let obj = this.$store.state.lcLabel
   let lcb = obj[lc]
    let title = ""
    if (lcb)
      title += lcb.title
   return title + this.$data.title_page + this.$data.title_group
  },
  updateTime(){
    //return "\n[更新时间:10:05]"
    return  " [" + this.$store.state.updateTime + "]"
  },
  isScsjNavFixed(){
  return this.$store.state.isScsjNavFixed
  }
},

mounted(){
            window.addEventListener('scroll', this.initHeight);
            this.$nextTick(function(){
            this.offsetTop_header  = document.querySelector('#header').offsetTop;
             //console.log(this.offsetTop_header)
            })
        },

methods:{
  setPage(action){
    this.$data.title_page = " - " + action.name 
    this.$data.title_group = ""
    let gr = action.value.group
    if (action.value.fid){
        this.$store.state.itemLabelId = ""
        let data = {fid:action.value.fid,lc:this.$route.params.lc}
        this.$store.dispatch('getItemLabel',data)
    }
    else if(gr){
        this.$store.state.itemLabelId = "selectGroup"
    }
    if (gr){
      this.$data.pageGroup = gr.map((item)=>{
                return {
                          value:item,
                          name:item.title,
                          method:this.getItemLabel
                        }

          })
      this.$data.groupSheetVisible = true
    }else{
      this.$data.pageGroup = [{name:'没有分组'}]
    }
  },
  getItemLabel(action){
        //console.log(action)
        this.$data.title_group  = " - " + action.name 
        this.$store.state.itemLabelId = ""
        this.$store.dispatch('getItemLabel',{fid:action.value.fid,lc:this.$route.params.lc})
  },

 initHeight(){
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      this.isFixed_header = scrollTop > this.offsetTop_header ? true : false;
 },

},
 destroyed() {
   window.removeEventListener('scroll', this.handleScroll)
 },
}
</script>
